<template>
	<div style="padding:0 5%;margin-top: 20px;">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/companyActive' }">公司业务</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/tenderTrain' }">招投标培训</el-breadcrumb-item>
			<el-breadcrumb-item>课程详情</el-breadcrumb-item>
		</el-breadcrumb>
		<div style="margin: 50px 0;border-radius: 5px;text-align: center;">
			<div style="display: inline-block;width:65%;border: #EBEEF5 1px solid;text-align:left;padding: 20px;box-sizing: border-box;margin-right:2%;">
				<div>
					<div style="display: inline-block;width: 40%;vertical-align: middle;">
						<img src="../../../assets/image/招标采购全过程审计.png" style="width: 100%;"/>
					</div><div style="display: inline-block;width:50%;text-align: left;padding-left: 14px;vertical-align: middle;">	
						<h3>{{ youshi[0].title }}</h3>
						<h4 style="color: #909399;">{{ youshi[0].desc }}</h4>
						<h4 style="color: #909399;">{{ youshi[0].desc2 }}</h4>
					</div>
				</div>
				<div>
					<h3>适用人群</h3>
					<p>对PPT动画有需求或感兴趣的大学生及职场人士。</p>
					<h3>课程概述</h3>
					<p>不论你是PPT动画小白，还是想在PPT动画有突破，这套教程一定让你获益匪浅！</p>
					<p>我们授人以鱼更授人以渔。案例是讲不完的，只有掌握了方法和思想，才能举一反三触类旁通。不仅教你PPT动画的操作，更教你动画的思想，剖析技巧本质，颠覆你对PPT动画的想象！
					</p>
					<p>*【可以先看看本套教程的章节1，可以免费阅读，了解课程】</p>
					<p>*【购买福利】</p>
					<p>1、学员交流群。我们会提供详细的疑问解答服务</p>
					<p>2、文平的所有公开PPT作品合集，课程所有课件</p>
					<p>3、赠送价值215元的收费PPT模板</p>
					<p>4、赠送 PPT制作的模仿苹果107快闪的 PPT模板</p>
					<p>*【粗暴提醒】</p>
					<p>1、越早购买优惠越多</p>
					<p>2、每周五更新3~4课时</p>
					<p>3、有疑问请联系讲师</p>
					<p>*【课程内容，预计81课时】</p>
					<p>章节1、本套课程你会学到什么（3课时）</p>
					<p>章节2、PPT动画快速入门（13课时）</p>
					<p>章节3、一个完整动态PPT制作案例（11课时）</p>
					<p>章节4、PPT动画进阶，包括标题、图表图示、触发器、折纸等（36课时）</p>
					<p>章节5、PPT高手进阶之简洁大气的图片展示动画（5课时）</p>
					<p>章节6、PPT动画大神练成之PPT来制作WWDC2013苹果开场视频（5课时）</p>
					<p>章节7、意外旅程之纯PPT制作苹果107秒快闪(5课时)</p>
				</div>
			</div>
			<div style="display: inline-block;width:29%;border: #EBEEF5 1px solid;text-align:left;vertical-align: top;">
				<h3 style="padding-left: 10px;">其他热门课程</h3>
				<div style="padding: 20px; text-align: center;">
					<div style="vertical-align: middle;">
						<img src="../../../assets/image/招标采购全过程审计.png" style="width: 100%;"/>
					</div>
					<div style="text-align: left;padding-left: 14px;vertical-align: middle;">	
						<h3>{{ youshi[0].title }}</h3>
						<h4 style="color: #909399;">{{ youshi[0].desc }}</h4>
						<h4 style="color: #909399;">{{ youshi[0].desc2 }}</h4>
					</div>
				</div>
				<div style="padding: 20px; text-align: center;">
					<div style="vertical-align: middle;">
						<img src="../../../assets/image/招标采购全过程审计.png" style="width: 100%;"/>
					</div>
					<div style="text-align: left;padding-left: 14px;vertical-align: middle;">	
						<h3>{{ youshi[0].title }}</h3>
						<h4 style="color: #909399;">{{ youshi[0].desc }}</h4>
						<h4 style="color: #909399;">{{ youshi[0].desc2 }}</h4>
					</div>
				</div>
				<div style="padding: 20px; text-align: center;">
					<div style="vertical-align: middle;">
						<img src="../../../assets/image/招标采购全过程审计.png" style="width: 100%;"/>
					</div>
					<div style="text-align: left;padding-left: 14px;vertical-align: middle;">	
						<h3>{{ youshi[0].title }}</h3>
						<h4 style="color: #909399;">{{ youshi[0].desc }}</h4>
						<h4 style="color: #909399;">{{ youshi[0].desc2 }}</h4>
					</div>
				</div>
				<div style="padding: 20px; text-align: center;">
					<div style="vertical-align: middle;">
						<img src="../../../assets/image/招标采购全过程审计.png" style="width: 100%;"/>
					</div>
					<div style="text-align: left;padding-left: 14px;vertical-align: middle;">	
						<h3>{{ youshi[0].title }}</h3>
						<h4 style="color: #909399;">{{ youshi[0].desc }}</h4>
						<h4 style="color: #909399;">{{ youshi[0].desc2 }}</h4>
					</div>
				</div>
			</div>
		</div>
		<!--
		<div style="margin: 50px 0;text-align: center;">
			<h1>招投标培训</h1>
			<div style="width: 50%;display: inline-block;vertical-align: middle;">
				<img src="../../../assets/images/bk.png" style="width: 100%;"/>
			</div><div style="width: 50%;display: inline-block;vertical-align: middle;">
				<h3 style="padding-left: 20px;" align="left">
					专业培训<br>我们提供的培训服务涵盖了政府采购和招标的法律法规、企业战略管理、公共关系管理、业务运营与管控等课程，分享实战经验帮助客户提升专业管理能力。
				</h3>
				<h3 style="padding-left: 20px;" align="left">
					培训对象<br>采购人、供应商、监管部门、专家。
				</h3>
			</div>
		</div>
		<div style="margin: 50px 0;text-align: center;">
			<h1>服务优势</h1>
			<img src="../../../assets/images/bk.png" style="width: 100%;"/>
		</div>
		<div style="margin: 50px 0;">
			<h1 style="text-align: center;">经典课程</h1>
			<el-row>
				<el-col :span="6" v-for="item in youshi" :key="item.id">
					<div style="margin: 20px;">
						<el-card :body-style="{padding: '0px' }">
								<img :src="item.src" class="image"  style="width: 100%;"/>
								<div style="padding: 14px">
									<h3>{{ item.title }}</h3>
									<h4 style="color: #909399;">{{ item.desc }}</h4>
									<h4 style="color: #909399;">{{ item.desc2 }}</h4>
								</div>
						</el-card>
					</div>
					
				</el-col>
			</el-row>
		</div>
		<div style="margin: 50px 0;">
			<h1 style="text-align: center;">培训见证</h1>
			<el-carousel :interval="4000" type="card" height="400px" style="text-align: center;">
				<el-carousel-item v-for="item in 6" :key="item">
					<h3 class="medium">{{ item }}</h3>
				</el-carousel-item>
			</el-carousel>
		</div>
		-->
	</div>
</template>
<script>
export default {
	name:'tender',
	data() {
		return {
			youshi: [
				{
				id: 1,
				src: require("../../../assets/image/精品标书.png"),
				title: "课程名称",
				desc:'共999分钟 | 讲师名字',
				desc2:'共999人学过',
				},
				{
				id: 2,
				src: require("../../../assets/image/培训服务.png"),
				title: "课程名称",
				desc:'共999分钟 | 讲师名字',
				desc2:'共999人学过',
				},
				{
				id: 3,
				src: require("../../../assets/image/咨询服务.jpg"),
				title: "课程名称",
				desc:'共999分钟 | 讲师名字',
				desc2:'共999人学过',
				},
				{
				id: 4,
				src: require("../../../assets/image/信息化服务.png"),
				title: "课程名称",
				desc:'共999分钟 | 讲师名字',
				desc2:'共999人学过',
				}
			],
			anli: [
				{
				id: 1,
				src: require("../../../assets/image/精品标书.png"),
				title: "业务经验丰富",
				desc:'内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍',
				},
				{
				id: 2,
				src: require("../../../assets/image/培训服务.png"),
				title: "服务便利性",
				desc:'内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍',
				},
				{
				id: 3,
				src: require("../../../assets/image/咨询服务.jpg"),
				title: "专业团队",
				desc:'内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍',
				},
				{
				id: 4,
				src: require("../../../assets/image/信息化服务.png"),
				title: "良好口碑",
				desc:'内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍内容介绍',
				}
			],
		}
	},
	methods: {
	},
	mounted:function(){
		this.bus.$emit('headerImg',this.headerImg);
	}
}
</script>

<style scoped>
	.titleInfo{margin: 20px;}
	.titleInfo span{margin-right: 10px;color:#909399;}
	.el-carousel__item h3 {
	color: #475669;
	font-size: 14px;
	opacity: 0.75;
	line-height: 200px;
	margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
	background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
	background-color: #d3dce6;
	}
</style>
